.container-news{
    max-width: 800px;
    margin: 0 auto;
}
.form_label{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
}
.input-fiels{
   font-size: 16px;
   padding: 4px;
   border: 2px solid #cacaca;
   border-radius: 4px;
   width: 100%;

}

.cover-upload{
    width: 100%;
    border: 2px dashed #cacaca;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.cover-upload p{
    font-size: 16px;
    padding: 50px 0;
    font-weight: 500;
}

.no-resize{
    resize: none;
}
.coverImage{
    width: 100%;
    aspect-ratio: 16/9;
    margin-top: 20px;
    border-radius: 4px;
}
.keyword-cta{
    position: absolute;
    right: 10px;
    transform: translateY(-50%) ;
    top: 50%;
    border: 1px solid black;
    border-radius: 4px;
    padding: 3px 10px;
    background-color: transparent;
}

.keyword{
    font-size: 13px;
    margin-top: 6px;
    background-color: #00bed7ad;
    padding: 3px 6px;
    border-radius: 4px;
}

.form_lable-input{
    font-size: 14px;
    border: none;
    font-weight: 600;
    color: #000;
    line-height: 21px;
    width: 100%;
    margin-bottom: 10px;
    padding: 4px 2px;
}

.funding-editor-header{
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 5px 0;
}

.info-header{
    font-size: 18px;
    font-weight: 600;
    
}
.info-text{
    margin-top: 6px;
    font-size: 14px;
}
.info-example{
    margin-top: 2px;
    background-color: #000;
    padding: 4px;
    border-radius: 4px;
    width: 100%;
    color: #fff;
    font-size: 14px;
}

.font_14{
    font-size: 14px !important;
}
@font-face {
  font-family: "AgusSans";
  src: local("AgusSans"),
    url(./Assets/Fonts/AgusSans/AgusSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito-light";
  src: local("Nunito-light"),
    url(./Assets/Fonts/nunito/Nunito-Light.ttf) format("truetype");
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.mail_dashboard .MuiTab-root{
  font-size: 16px !important;
  text-align: left !important;
  justify-content: start;
  align-items: flex-start;
  text-transform: capitalize;
}

.gap-20{
  gap: 20px;

}
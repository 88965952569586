.footer-root {
    display: flex;
    justify-content: center;
    background-color: #F7F9FE;
    ;
}

.footer-main {
    padding: 8rem 0;
    width: 75%;
}

.foooter-logo {
    padding-bottom: 20px;
    border-bottom: 4px solid #eaeaea;
    margin-bottom: 24px;
}

.foooter-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-link>a {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #585858;
}

@media (max-width:768px) {
    .foooter-links{
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width:576px) {
    .footer-main{
        padding: 6rem 0 4rem 0;
    }
    .foooter-links{
        margin-bottom: 24px;
    }
}
.dashboard-head {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dashboard-head img {
  height: 60px;
}

.dashboard-article_root {
  padding: 0 5rem;
}

.dashboard-topborder {
  border-top: 2px solid #4ad7d1;
}

.dashboard-article-root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-article-main {
  padding: 8rem 0;
  width: 75%;
}

.dashboard-article-root:nth-child(even) {
  background-color: #f7f9fe;
}

.dashboard-article-root:nth-child(odd) {
  background-color: #ffffff;
}

.dashboard-article_images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.dashboard-article_new {
  background: linear-gradient(90deg, #4ad7d1 0%, #00bfd7 100%);
  border-radius: 20px;
  height: 24px;
  padding: 0 14px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  color: white;
}

.dashboard-article-img > img {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  min-height: 164px;
}

.dashboard-article-img {
  cursor: pointer;
}

.article_topic-p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.article_topic-bottom {
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(2px);
  border-radius: 0px 0px 8px 8px;
  padding: 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-pagination {
  display: flex;
  justify-content: center;
}

.dashboard-page > ul > li:first-child > button,
.dashboard-page > ul > li:last-child > button {
  background: transparent;
  /* border-radius: 30px; */
}

.dashboard-page > ul > li > button {
  background: transparent;
  border-radius: 30px;
  font-size: 1.2rem;
}

.dashboard-page > ul > li > button.Mui-selected {
  background: #c9f0ff;
  border-radius: 30px;
}

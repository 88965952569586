.radio-images {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;

  margin-right: 24px;
  cursor: pointer;
}
.admin-form-label-main > input[type="radio"]:checked + .radio-images {
  border: 5px solid #000;
  border-radius: 10px;
}
.radio-images > img {
  width: 100%;
}
.admin-form-label-main {
  position: relative;
}
.admin-form-label-main > input[type="radio"] {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.admin-dashboard-form-root {
  height: 85vh;
  width: 100%;
}
.admin-dashboard-form {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-dashboard-form input,
.admin-dashboard-form textarea {
  padding: 5px 10px;
  font-size: 15px;
}
.admin-dashboard-input {
  display: flex;
  flex-direction: column;
}
.admin-dashboard-input > select {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #cacaca;
}
.admin-dashboard-input > input {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #cacaca;
  outline: none;
}
.admin-coverImage {
  width: 350px;
  height: 100%;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.admin-coverImage > img {
  width: 100%;
  height: 100%;
}

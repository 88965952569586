.dash-bottom-blue {
    border-bottom: 2px solid #4ad7d1;
}

.dash-total_views_main {
    background: #F7F9FE;
    border-radius: 10px;
    padding: 8px 20px 16px;
}

.dash-total_views_p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 63px;
    color: #4AD7D1;
}

.dash-total_views_p1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
}

.dash-total_views_p2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #404654;
}

.dash-total_views_weak_root {
    box-shadow: 2px 4px 30px 2px rgb(202 202 202 / 20%);
    border-radius: 16px;
    background: #FFFFFF;
    /* border-bottom: 1px solid rgba(202, 202, 202, 0.5); */
    margin-bottom: 70px;
}

.dash-total_views_weak_head {
    padding: 24px 40px;
    border-bottom: 2px solid #cacaca;
}

.dash-total_views_weak_main .MuiAccordion-root {
    padding: 0 24px;
    /* border-bottom: 1px solid rgba(202, 202, 202, 0.3); */
}

.dash-total_views_weak_main .MuiAccordion-root:last-child {
    border-bottom: none;
}

.dash-total_views_weak_ {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    /* border-bottom: 2px solid rgba(202, 202, 202, 0.3); */
}

.dash-total_views_weak_:last-child {
    border-bottom: none;
}

.dash-total_views_weak_img {
    width: 142.5px;
    min-width: 142.5px;
    height: 80px;
    border-radius: 8px;
}

.dash-total_views_p_maxW {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.dash-total-viewBox {
    padding: 10px 16px;
    background: rgba(201, 240, 255, 0.3);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
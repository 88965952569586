.editorh2{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 700;
}
.editorh4{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: bold;
}
.editorTitle1{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
}
.editorTitle2{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 500;
}
.editorCaption{
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
    font-weight: 700;
}
.twf_login_root {
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #000;
    color: white;
    /* align-items: center; */
}
.twf_login_main {
    height: fit-content;
    width: 500px;
    max-width: 90%;
    margin-top: 100px;
}
.twf_login_input {
    position: relative;
}
.twf_login_input input {
    border: 1px solid #e4e4e4;
    background: white;
    padding: 8px 10px;
    width: 100%;
    border-radius: 4px;
    outline: none;
}
.eye_btn {
    position: absolute;
    right: 0px;
    top: 0;
    height: 100%;
}
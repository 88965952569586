@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800;900;1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aref+Ruqaa&family=Mirza&family=Montserrat&family=Noto+Sans&family=Nunito&family=Open+Sans&family=Poppins&family=Ubuntu&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

Html {
  font-size: 10px;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-color: #4ad7d1;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.btn_cta {
  padding: 12px 24px !important;
  background: linear-gradient(90deg, #68c1d2 0%, #2bd2b6 99.99%) !important;
  border-radius: 8px !important;
  font-family: "Nunito" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-align: center !important;
  letter-spacing: 0.08em !important;
  color: #ffffff !important;
  cursor: pointer;
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

.toastBody {
  font-size: 2rem !important;
}

.trendistmailform,
.trendistmailform p,
.trendistmailform select,
.trendistmailform input,
.trendistmailform ul,
.trendistmailform li,
.trendistmailform button,
.trendistmailform label,
.trendistmailform tr,
.trendistmailform th {
  font-size: 15px !important;
}

.trendist_translate {
  font-size: 2rem !important;
}

/* @media print {
  .coverMargin {
    margin: -1cm 0;
  }
} */

@media print {
  @page {
    margin: 1cm 0;
    background-color: #f7f9fe;
    scroll-behavior: smooth;
  }

  html{
    scroll-behavior: smooth;
  }
  
  body {
    margin: 0;
    background-color: #f7f9fe;
    scroll-behavior: smooth;
  }

  /* body *:not(.coverMargin) {
    margin: 1cm;
  } */

  .coverMargin {
    margin: 0;
    /* position: absolute;
    top: -1cm;  */
  }

  .page-break {
    page-break-after: always;
  }

  .page-break-none {
    display: none;
  }
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  margin: 0;
  padding: 0;
}

a,
a:hover {
  color: inherit;
}

.Nunito {
  font-family: Nunito !important;
}

.v_center {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.h3_poppins_white {
  font-family: Poppins;
  font-display: swap;
  color: #ffffff;
  font-size: 40px;
  font-weight: 600;
}

.h3_poppins_white_20 {
  font-family: Poppins;
  font-display: swap;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
}

.zefy_CTA_01 {
  background: #404654;
  border-radius: 8px;
  padding: 8px 16px;
  color: #ffffff;
  font-family: Nunito;
  font-display: swap;
  border: none;
  font-weight: 600;
  border: 2px solid transparent;
  height: fit-content;
  font-size: 12px;
}

.zefy_CTA_01_same_cust {
  background: #404654;
  border-radius: 4px;
  padding: 8px 16px;
  color: #ffffff;
  font-family: Nunito;
  font-display: swap;
  border: none;
  font-weight: 500;
  border: 2px solid transparent;
  height: fit-content;
  font-size: 12px;
}

.zefy_CTA_01_big_padding {
  background: #404654;
  border-radius: 8px;
  padding: 16px 24px;
  color: #ffffff;
  font-family: Nunito;
  font-display: swap;
  border: none;
  font-weight: 600;
  border: 2px solid transparent;
  height: fit-content;
  font-size: 12px;
}

.zefy_CTA_01_wider {
  background: #404654;
  border-radius: 4px;
  padding: 4px 24px;
  color: #ffffff;
  font-family: Nunito;
  font-display: swap;
  border: none;
  font-weight: 500;
  border: 2px solid transparent;
  height: fit-content;
  font-size: 12px;
}

.zefy_CTA_01_docs {
  background: #404654;
  border-radius: 4px;
  padding: 16px 32px;
  color: #ffffff;
  font-family: Nunito;
  font-display: swap;
  border: none;
  font-weight: 500;
  border: 2px solid transparent;
  height: fit-content;
  font-size: 12px;
}

.zefy_CTA_01_bigger {
  background: #404654;
  border-radius: 6px;
  padding: 12px 36px;
  color: #ffffff;
  font-family: Nunito;
  font-display: swap;
  border: none;
  font-weight: 500;
  border: 2px solid transparent;
  height: fit-content;
  font-size: 12px;
}

.zefy_CTA_01_img {
  background: transparent;
  border-radius: 6px;
  padding: 8px 12px;
  color: #757575;
  font-family: Nunito;
  font-display: swap;
  border: #757575;
  font-weight: 500;
  border: 1px solid #757575 !important;
  height: fit-content;
  font-size: 10px;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.zefy_CTA_01_img_grey_outline {
  background: transparent;
  border-radius: 6px;
  padding: 8px 12px;
  color: #757575;
  font-family: Nunito;
  font-display: swap;
  border: #757575;
  font-weight: 500;
  border: 1px solid #757575 !important;
  font-size: 12px;
  cursor: pointer;
  line-height: 16px;
}

.zefy_CTA_01_me {
  background: #404654;
  border-radius: 8px;
  padding: 3px 20px 8px 20px;
  color: #ffffff;
  font-family: Nunito;
  font-display: swap;
  border: none;
  font-weight: 600;
  border: 2px solid transparent;
}

.zefy_CTA_01_black_outline {
  background: transparent;
  border-radius: 8px;
  padding: 8px 16px;
  color: #000;
  font-family: Nunito;
  font-display: swap;
  border: 2px solid #000;
  font-weight: 600;
  height: fit-content;
  font-size: 12px;
}

.zefy_CTA_01_white_outline {
  background: transparent;
  border-radius: 8px;
  padding: 10px 15px;
  color: #fff;
  font-family: Nunito;
  font-display: swap;
  border: 2px solid #fff;
  font-weight: 600;
}

.zefy_CTA_01_white_outline_2 {
  background: transparent;
  border-radius: 8px;
  padding: 10px 10px;
  color: #fff;
  font-family: Nunito;
  font-display: swap;
  border: 2px solid #fff;
  font-weight: 600;
}

.zefy_CTA_01_outline {
  background: transparent;
  border-radius: 8px;
  padding: 8px 16px;
  color: #000;
  font-family: Nunito;
  font-display: swap;
  border: 2px solid rgba(104, 193, 210, 1);
  font-weight: 600;
  height: fit-content;
  font-size: 12px;
}

.zefy_CTA_01_small {
  background: #404654;
  border-radius: 8px;
  padding: 7px 12px;
  color: #ffffff;
  font-family: Nunito;
  font-display: swap;
  border: none;
  font-weight: 600;
}

.zefy_CTA_01_small_outline {
  background: transparent;
  border-radius: 8px;
  padding: 7px 12px;
  color: #000;
  font-family: Nunito;
  font-display: swap;
  border: 2px solid rgba(104, 193, 210, 1);
  font-weight: 600;
}

.zefy_CTA_01_small_outline2 {
  background: transparent;
  border-radius: 8px;
  padding: 6px 9px;
  color: #000;
  font-family: Nunito;
  font-display: swap;
  border: 2px solid rgba(104, 193, 210, 1);
  font-weight: 600;
}

.zefy_CTA_01_small_bg_black {
  background: #000000;
  border-radius: 4px;
  padding: 8px 20px;
  color: white;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  border: none;
}

.zefy_CTA_01_small_bg_white {
  background: #ffffff;
  border-radius: 4px;
  padding: 10px 20px;
  color: #000;
  border: 1px solid #000;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  border: none;
}

.zefy_CTA_01_small_br_black_outline {
  background: transparent;
  border: 1px solid #1b1f1f;
  border-radius: 4px;
  padding: 10px 20px;
  color: #000;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}

.zefy_CTA_02_small_fill_width {
  background: #000000;
  box-shadow: 0px 4px 20px rgb(111 111 111 / 5%);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  padding: 12px 0;
  border-radius: 0 0 8px 8px;
  /* transform: matrix(1, 0, 0, -1, 0, 0); */
}

.zefy_CTA_04_md_fill_width {
  background: #000000;
  padding: 12px 24px;
  color: white;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  border-radius: 8px;
}

.zefy_CTA_04_md_fill_width_outline {
  background: transparent;
  padding: 12px 24px;
  color: black;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  border-radius: 8px;
}

.aqua {
  color: #4AD7D1;
}

.aqua2 {
  color: rgba(104, 193, 210, 1);
}

.aqua3 {
  color: #2bd2b6;
}

.aqua_gradient {
  background: linear-gradient(90deg, #68c1d2 0%, #2bd2b6 99.99%);
}

.aqua2_bg {
  background: #68c1d24d;
}

.aqua_gradient_text {
  color: #239f99 !important;
}

.row_between {
  display: flex;
  justify-content: space-between;
  margin: 0;
  align-items: center;
  flex-wrap: wrap;
}

.row_align {
  display: flex;
  margin: 0;
  align-items: center;
  flex-wrap: wrap;
}

.font25 {
  font-size: 25px !important;
}

.pink_bg {
  background: rgba(229, 105, 151, 0.1) !important;
}

.yellow_bg {
  background: rgba(251, 199, 64, 0.1) !important;
}

.light_pink_bg {
  background: rgba(189, 151, 203, 0.1);
}

.green_bg {
  background: rgba(168, 249, 73, 0.1) !important;
}

.white_bg {
  background: #ffffff;
}

.blue_bg {
  background: rgba(104, 193, 210, 0.1) !important;
}

.cyan_bg {
  background: rgba(74, 215, 209, 0.1);
}

.pink_bg_br {
  border: 1px solid rgba(229, 105, 151, 0.5);
  background: #fff;
}

.yellow_bg_br {
  border: 1px solid rgba(251, 199, 64, 1);
  background: #fff;
}

.light_pink_bg_br {
  border: 1px solid rgba(189, 151, 203, 0.5);
  background: #fff;
}

.green_bg_br {
  border: 1px solid rgba(168, 249, 73, 1);
  background: #fff;
}

.blue_bg_br {
  border: 1px solid rgba(104, 193, 210, 1);
  background: #fff;
}

.cyan_bg_br {
  border: 1px solid rgba(74, 215, 209, 0.5) !important;
  background: #fff;
}

.purple_bg_br {
  border: 1px solid rgba(189, 151, 203, 1);
  background: #fff;
}

.pink_bg_br:hover {
  border: 1px solid rgba(229, 105, 151, 0.5);
  background: rgba(229, 105, 151, 0.1);
}

.yellow_bg_br:hover {
  border: 1px solid rgba(251, 199, 64, 1);
  background: rgba(251, 199, 64, 0.1);
}

.light_pink_bg_br:hover {
  border: 1px solid rgba(189, 151, 203, 0.5);
  background: rgba(189, 151, 203, 0.1);
}

.green_bg_br:hover {
  border: 1px solid rgba(168, 249, 73, 1);
  background: rgba(168, 249, 73, 0.01);
}

.blue_bg_br:hover {
  border: 1px solid rgba(104, 193, 210, 1);
  background: rgba(104, 193, 210, 0.1);
}

.cyan_bg_br:hover {
  border: 1px solid rgba(74, 215, 209, 0.5);
  background: rgba(74, 215, 209, 0.1);
}

.purple_bg_br:hover {
  border: 1px solid rgba(189, 151, 203, 1);
  background: rgba(189, 151, 203, 0.1);
}

.dark_pink_bg {
  background: rgba(255, 142, 156, 1) !important;
}

.dark_yellow_bg {
  background: rgba(251, 199, 64, 1) !important;
}

.dark_purple_bg {
  background: rgba(189, 151, 203, 1) !important;
}

.p_nunito_14 {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #404654;
}

.p_nunito_14_grey {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #404654;
}

.p_nunito_13_grey {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #404654;
}

.p_nunito_13_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #000000;
}

.h1_nunito_20_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #000000;
}

.h1_nunito_24_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.04em;
  /* text-transform: uppercase; */

  color: #000000;
}

.h1_poppins_24_black {
  font-family: Poppins;
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.04em;
  /* text-transform: uppercase; */

  color: #000000;
}

.h1_nunito_24_white {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.04em;
  /* text-transform: uppercase; */

  color: #ffffff;
}

.h1_nunito_16_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 120% */

  letter-spacing: 0.04em;
  /* text-transform: uppercase; */

  color: #000000;
}

.h1_nunito_14_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 120% */

  letter-spacing: 0.04em;
  /* text-transform: uppercase; */

  color: #000000;
}

.p_nunito_14_gre {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #757575;
}

.p_nunito_14_white {
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #ffffff;
}

.p_nunito_18_grey {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #757575;
}

.p_nunito_18_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #000000;
}

.p_nunito_14_black {
  font-family: Nunito !important;
  font-display: swap;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  /* or 129% */

  letter-spacing: 0.02em !important;

  color: #000000 !important;
}

.p_nunito_10 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #ffffff;
}

.p_nunito_10_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #000000;
}

.p_nunito_10_red {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #ff725e;
}

.p_nunito_10_grey {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #404654;
}

.p_nunito_16_cust {
  font-family: "Nunito";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #ffffff;
}

.no_text_transform {
  text-transform: none !important;
}

.p_nunito_16_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #000000;
}

.p_nunito_12_blue {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #2bd2b6;
}

.p_nunito_12_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #000000;
}

.p_nunito_20_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* or 129% */

  letter-spacing: 0.04em;

  color: #000000;
}

.p_poppins_24_black {
  font-family: Poppins;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  /* or 129% */

  letter-spacing: 0.04em;

  color: #000000;
}

.p_nunito_12_red {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #ff4f4f;
}

.p_nunito_12_grey {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #404654;
}

.sq_avatar {
  border-radius: 50% !important;
  height: 2rem !important;
  width: 2rem !important;
}

.sq_avatar2 {
  border-radius: 50% !important;
  height: 4rem !important;
  width: 4rem !important;
}

.btn_customize {
  padding-top: 6px !important;
  border-radius: 5px;
  align-items: center;
  padding-bottom: 6px !important;
  padding-left: 6px !important;
  padding-right: 12px !important;
  background: linear-gradient(90deg,
      rgba(104, 193, 210, 0.1) 0%,
      rgba(43, 210, 182, 0.1) 99.99%) !important;
  height: 38px !important;
  margin-top: 5px !important;
  width: 61px !important;
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 10px;
}

.h1_nunito_16 {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  letter-spacing: 0.02em;
  /* 239f99 */
  color: #239f99 !important;
  border-radius: 4px !important;
}

.p_nunito_11 {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  /* or 120% */
  letter-spacing: 0.02em;
  color: #404654;
}

.p_nunito_11_grey {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  /* or 120% */

  letter-spacing: 0.02em;

  color: #757575;
}

.btn_equal_padding {
  border-color: transparent;
  border-radius: 4px;
  padding: 4px;
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
  /* identical to box height, or 125% */

  letter-spacing: 0.02em;

  /* 239f99 */

  color: #239f99;
  background: linear-gradient(90deg,
      rgba(104, 193, 210, 0.1) 0%,
      rgba(43, 210, 182, 0.1) 99.99%);
}

.redAstrick {
  color: #ff5d5b;
}

.h1_nunito_25_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #000000;
}

.display_inline {
  display: inline !important;
}

.display_block {
  display: block !important;
}

.no_margin {
  margin: 0px !important;
}

.fw-44 {
  font-weight: 400 !important;
}

.fw-4 {
  font-weight: 400 !important;
}

.fw-5 {
  font-weight: 500 !important;
}

.fw-6 {
  font-weight: 600 !important;
}

.fw-7 {
  font-weight: 700 !important;
}

.fw-8 {
  font-weight: 800 !important;
}

.fw-9 {
  font-weight: 900 !important;
}

.fw-b {
  font-weight: bold !important;
}

.fw-bb {
  font-weight: bolder !important;
}

/* margin-padding */
.mt-4 {
  margin-top: 4px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-14 {
  margin-top: 14px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-56 {
  margin-top: 56px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-64 {
  margin-top: 64px !important;
}

.mt-72 {
  margin-top: 72px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mr-14 {
  margin-right: 14px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-6 {
  margin-left: 6px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-48 {
  margin-left: 48px !important;
}

.ml-60 {
  margin-left: 40px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-64 {
  margin-bottom: 64px !important;
}

.mb--5 {
  margin-bottom: -5px !important;
}

.p-24 {
  padding: 24px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pt-6 {
  padding-top: 6px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.pr-6 {
  padding-right: 6px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-6 {
  padding-left: 6px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pb-6 {
  padding-bottom: 6px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.p-10 {
  padding: 10px;
}

.p-16 {
  padding: 16px !important;
}

.p-4 {
  padding: 4px;
}

.p-6 {
  padding: 6px;
}

.p-40 {
  padding: 40px !important;
}

.p-8 {
  padding: 8px;
}

/* imp classes */
h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 85px;
  line-height: 128px;
}

h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 65px;
  line-height: 98px;
}

h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 49px;
  line-height: 74px;
}

h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 57px;
}

h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 29px;
  line-height: 44px;
}

h6 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
}

.head6 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.0015em;
}

.head4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 51px;
  letter-spacing: 0.0015em;
}

.title {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-align: left;
}

.title2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.titleNunito {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
}

.title1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

.body1_pop {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.body1 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.body2 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.body2_inter {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.caption {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.overline {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
}

.color_light_grey {
  color: #757575;
}

.color_grey_af {
  color: #bd8d8d;
}

.color_grey_40 {
  color: #404556;
}

.color_grey_46 {
  color: #404654;
}

.color_half_black {
  color: rgba(0, 0, 0, 0.5);
}

.grey_58 {
  color: #585858;
}

.grey_75 {
  color: #757575;
}

.color__1D {
  color: #1d1d1d;
}

.pointer {
  cursor: pointer;
}

.trans_cta {
  border: none;
  outline: none;
  background-color: transparent;
}

.purple_bg01 {
  background: rgba(82, 76, 132, 0.25);
}

.pink_bg01 {
  background: rgba(241, 192, 195, 0.25);
}

.dashboard_001_block {
  padding: 20px;
  border-radius: 8px;
  background-color: white;
}

.dashboard_002_block {
  padding: 20px 16px;
  border-radius: 8px;
  background-color: white;
}

.border_full_db {
  border: 1px solid #dbdbdb;
}

.border_full_ca_half {
  border: 0.5px solid #dbdbdb;
}

.border_btm_ca {
  border-bottom: 1px solid #cacaca;
}

.grey_ca{
  color: #cacaca;
}

.transparent_chip {
  padding: 8px;
  background: transparent;
  border: 1px solid #cacaca;
  border-radius: 100px;
}

.p_chip5_grey {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #757575;
  padding: 9px;
  background: rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.back_p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #afafaf;
  display: flex;
  align-items: center;
}

.back_p>span {
  width: 40px;
  height: 2px;
  background: #afafaf;
}

@media (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0px !important;
  }

  .mt-sm-4 {
    margin-top: 4px !important;
  }

  .mt-sm-8 {
    margin-top: 8px !important;
  }

  .mt-sm-6 {
    margin-top: 6px !important;
  }

  .mt-sm-10 {
    margin-top: 10px !important;
  }

  .mt-sm-12 {
    margin-top: 12px !important;
  }

  .mt-sm-14 {
    margin-top: 14px !important;
  }

  .mt-sm-16 {
    margin-top: 16px !important;
  }

  .mt-sm-20 {
    margin-top: 20px !important;
  }

  .mt-sm-24 {
    margin-top: 24px !important;
  }

  .mt-sm-30 {
    margin-top: 30px !important;
  }

  .mt-sm-32 {
    margin-top: 32px !important;
  }

  .mt-sm-36 {
    margin-top: 36px !important;
  }

  .mt-sm-40 {
    margin-top: 40px !important;
  }

  .mt-sm-48 {
    margin-top: 48px !important;
  }

  .mt-sm-50 {
    margin-top: 50px !important;
  }

  .mt-sm-56 {
    margin-top: 56px !important;
  }

  .mt-sm-60 {
    margin-top: 60px !important;
  }

  .mt-sm-64 {
    margin-top: 64px !important;
  }

  .mt-sm-72 {
    margin-top: 72px !important;
  }

  .mt-sm-80 {
    margin-top: 80px !important;
  }

  .mr-sm-0 {
    margin-right: 0px !important;
  }

  .mr-sm-4 {
    margin-right: 4px !important;
  }

  .mr-sm-40 {
    margin-right: 40px !important;
  }

  .mr-sm-8 {
    margin-right: 8px !important;
  }

  .mr-sm-6 {
    margin-right: 6px !important;
  }

  .mr-sm-10 {
    margin-right: 10px !important;
  }

  .mr-sm-12 {
    margin-right: 12px !important;
  }

  .mr-sm-14 {
    margin-right: 14px !important;
  }

  .mr-sm-16 {
    margin-right: 16px !important;
  }

  .mr-sm-20 {
    margin-right: 20px !important;
  }

  .mr-sm-24 {
    margin-right: 24px !important;
  }

  .mr-sm-32 {
    margin-right: 32px !important;
  }

  .ml-sm-0 {
    margin-left: 0px !important;
  }

  .ml-sm-8 {
    margin-left: 8px !important;
  }

  .ml-sm-6 {
    margin-left: 6px !important;
  }

  .ml-sm-10 {
    margin-left: 10px !important;
  }

  .ml-sm-12 {
    margin-left: 12px !important;
  }

  .ml-sm-16 {
    margin-left: 16px !important;
  }

  .ml-sm-24 {
    margin-left: 24px !important;
  }

  .ml-sm-32 {
    margin-left: 32px !important;
  }

  .ml-sm-20 {
    margin-left: 20px !important;
  }

  .ml-sm-40 {
    margin-left: 40px !important;
  }

  .ml-sm-48 {
    margin-left: 48px !important;
  }

  .ml-sm-60 {
    margin-left: 60px !important;
  }

  .mb-sm-0 {
    margin-bottom: 0px !important;
  }

  .mb-sm-8 {
    margin-bottom: 8px !important;
  }

  .mb-sm-6 {
    margin-bottom: 6px !important;
  }

  .mb-sm-10 {
    margin-bottom: 10px !important;
  }

  .mb-sm-12 {
    margin-bottom: 12px !important;
  }

  .mb-sm-16 {
    margin-bottom: 16px !important;
  }

  .mb-sm-20 {
    margin-bottom: 20px !important;
  }

  .mb-sm-24 {
    margin-bottom: 24px !important;
  }

  .mb-sm-32 {
    margin-bottom: 32px !important;
  }

  .mb-sm-40 {
    margin-bottom: 40px !important;
  }

  .mb-sm--5 {
    margin-bottom: -5px !important;
  }
}

@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0px !important;
  }

  .mt-md-4 {
    margin-top: 4px !important;
  }

  .mt-md-8 {
    margin-top: 8px !important;
  }

  .mt-md-6 {
    margin-top: 6px !important;
  }

  .mt-md-10 {
    margin-top: 10px !important;
  }

  .mt-md-12 {
    margin-top: 12px !important;
  }

  .mt-md-14 {
    margin-top: 14px !important;
  }

  .mt-md-16 {
    margin-top: 16px !important;
  }

  .mt-md-20 {
    margin-top: 20px !important;
  }

  .mt-md-24 {
    margin-top: 24px !important;
  }

  .mt-md-30 {
    margin-top: 30px !important;
  }

  .mt-md-32 {
    margin-top: 32px !important;
  }

  .mt-md-36 {
    margin-top: 36px !important;
  }

  .mt-md-40 {
    margin-top: 40px !important;
  }

  .mt-md-48 {
    margin-top: 48px !important;
  }

  .mt-md-50 {
    margin-top: 50px !important;
  }

  .mt-md-56 {
    margin-top: 56px !important;
  }

  .mt-md-60 {
    margin-top: 60px !important;
  }

  .mt-md-64 {
    margin-top: 64px !important;
  }

  .mt-md-72 {
    margin-top: 72px !important;
  }

  .mt-md-80 {
    margin-top: 80px !important;
  }

  .mr-md-0 {
    margin-right: 0px !important;
  }

  .mr-md-4 {
    margin-right: 4px !important;
  }

  .mr-md-40 {
    margin-right: 40px !important;
  }

  .mr-md-8 {
    margin-right: 8px !important;
  }

  .mr-md-6 {
    margin-right: 6px !important;
  }

  .mr-md-10 {
    margin-right: 10px !important;
  }

  .mr-md-12 {
    margin-right: 12px !important;
  }

  .mr-md-14 {
    margin-right: 14px !important;
  }

  .mr-md-16 {
    margin-right: 16px !important;
  }

  .mr-md-20 {
    margin-right: 20px !important;
  }

  .mr-md-24 {
    margin-right: 24px !important;
  }

  .mr-md-32 {
    margin-right: 32px !important;
  }

  .ml-md-0 {
    margin-left: 0px !important;
  }

  .ml-md-8 {
    margin-left: 8px !important;
  }

  .ml-md-6 {
    margin-left: 6px !important;
  }

  .ml-md-10 {
    margin-left: 10px !important;
  }

  .ml-md-12 {
    margin-left: 12px !important;
  }

  .ml-md-16 {
    margin-left: 16px !important;
  }

  .ml-md-24 {
    margin-left: 24px !important;
  }

  .ml-md-32 {
    margin-left: 32px !important;
  }

  .ml-md-20 {
    margin-left: 20px !important;
  }

  .ml-md-40 {
    margin-left: 40px !important;
  }

  .ml-md-48 {
    margin-left: 48px !important;
  }

  .ml-md-60 {
    margin-left: 40px !important;
  }

  .mb-md-0 {
    margin-bottom: 0px !important;
  }

  .mb-md-8 {
    margin-bottom: 8px !important;
  }

  .mb-md-6 {
    margin-bottom: 6px !important;
  }

  .mb-md-10 {
    margin-bottom: 10px !important;
  }

  .mb-md-12 {
    margin-bottom: 12px !important;
  }

  .mb-md-16 {
    margin-bottom: 16px !important;
  }

  .mb-md-20 {
    margin-bottom: 20px !important;
  }

  .mb-md-24 {
    margin-bottom: 24px !important;
  }

  .mb-md-32 {
    margin-bottom: 32px !important;
  }

  .mb-md-40 {
    margin-bottom: 40px !important;
  }

  .mb-md--5 {
    margin-bottom: -5px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg-4 {
    margin-top: 4px !important;
  }

  .mt-lg-0 {
    margin-top: 0px !important;
  }

  .mt-lg-8 {
    margin-top: 8px !important;
  }

  .mt-lg-6 {
    margin-top: 6px !important;
  }

  .mt-lg-10 {
    margin-top: 10px !important;
  }

  .mt-lg-12 {
    margin-top: 12px !important;
  }

  .mt-lg-14 {
    margin-top: 14px !important;
  }

  .mt-lg-16 {
    margin-top: 16px !important;
  }

  .mt-lg-20 {
    margin-top: 20px !important;
  }

  .mt-lg-24 {
    margin-top: 24px !important;
  }

  .mt-lg-30 {
    margin-top: 30px !important;
  }

  .mt-lg-32 {
    margin-top: 32px !important;
  }

  .mt-lg-36 {
    margin-top: 36px !important;
  }

  .mt-lg-40 {
    margin-top: 40px !important;
  }

  .mt-lg-48 {
    margin-top: 48px !important;
  }

  .mt-lg-50 {
    margin-top: 50px !important;
  }

  .mt-lg-56 {
    margin-top: 56px !important;
  }

  .mt-lg-60 {
    margin-top: 60px !important;
  }

  .mt-lg-64 {
    margin-top: 64px !important;
  }

  .mt-lg-72 {
    margin-top: 72px !important;
  }

  .mt-lg-80 {
    margin-top: 80px !important;
  }

  .mr-lg-0 {
    margin-right: 0px !important;
  }

  .mr-lg-4 {
    margin-right: 4px !important;
  }

  .mr-lg-40 {
    margin-right: 40px !important;
  }

  .mr-lg-8 {
    margin-right: 8px !important;
  }

  .mr-lg-6 {
    margin-right: 6px !important;
  }

  .mr-lg-10 {
    margin-right: 10px !important;
  }

  .mr-lg-12 {
    margin-right: 12px !important;
  }

  .mr-lg-14 {
    margin-right: 14px !important;
  }

  .mr-lg-16 {
    margin-right: 16px !important;
  }

  .mr-lg-20 {
    margin-right: 20px !important;
  }

  .mr-lg-24 {
    margin-right: 24px !important;
  }

  .mr-lg-32 {
    margin-right: 32px !important;
  }

  .ml-lg-0 {
    margin-left: 0px !important;
  }

  .ml-lg-8 {
    margin-left: 8px !important;
  }

  .ml-lg-6 {
    margin-left: 6px !important;
  }

  .ml-lg-10 {
    margin-left: 10px !important;
  }

  .ml-lg-12 {
    margin-left: 12px !important;
  }

  .ml-lg-16 {
    margin-left: 16px !important;
  }

  .ml-lg-24 {
    margin-left: 24px !important;
  }

  .ml-lg-32 {
    margin-left: 32px !important;
  }

  .ml-lg-20 {
    margin-left: 20px !important;
  }

  .ml-lg-40 {
    margin-left: 40px !important;
  }

  .ml-lg-48 {
    margin-left: 48px !important;
  }

  .ml-lg-60 {
    margin-left: 40px !important;
  }

  .mb-lg-0 {
    margin-bottom: 0px !important;
  }

  .mb-lg-8 {
    margin-bottom: 8px !important;
  }

  .mb-lg-6 {
    margin-bottom: 6px !important;
  }

  .mb-lg-10 {
    margin-bottom: 10px !important;
  }

  .mb-lg-12 {
    margin-bottom: 12px !important;
  }

  .mb-lg-16 {
    margin-bottom: 16px !important;
  }

  .mb-lg-20 {
    margin-bottom: 20px !important;
  }

  .mb-lg-24 {
    margin-bottom: 24px !important;
  }

  .mb-lg-32 {
    margin-bottom: 32px !important;
  }

  .mb-lg-40 {
    margin-bottom: 40px !important;
  }

  .mb-lg--5 {
    margin-bottom: -5px !important;
  }
}
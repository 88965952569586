
.options_root {
    overflow-x: hidden;
    min-height: 91vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .options_right_box_root {
    color: #000000;
    background: #ffffff;
    border-radius: 40px 0px 0px 40px;
    padding: 12px 40px;
    min-height: 550px;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }
  
  .options_right_box_ {
    padding: 32px 0;
    border-bottom: 1px solid #cacaca;
  }
  
  .options_right_box_:last-child {
    border-bottom: none;
  }
  
  .optionsP {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    color: #000000;
    display: flex;
    align-items: center;
    /* margin-left: 16px; */
  }
  
  .optionsP > span {
    margin-right: 16px;
  }
  
  .options_left_box_root {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }
  
  .options_left_box_backp {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #cacaca;
  }
  
  .options_left_box_back > span {
    height: 2px;
    background: #cacaca;
    width: 40px;
  }
  
  .options_right_box__hoverbtn1:hover svg circle {
    transition: all 0.2s ease-in-out;
    fill: #4ad7d1;
  }
  
  .options_right_box__hoverbtn2:hover svg path {
    transition: all 0.2s ease-in-out;
    fill: #4ad7d1;
  }
  
  .options_right_form_root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .options_right_box_pa {
    border-bottom: 1px solid #4ad7d1;
  }
  
  @media (max-width: 992px) {
    .options_right_box_root {
      width: 90%;
      margin-left: auto;
    }
  }
  
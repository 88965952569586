.trendistEditorRoot {
    min-height: 91vh;
}

.trendist_editor_form {
    padding: 12px 24px;
}

.editorOutputright {
    max-height: 84vh;
    /* overflow: hidden; */
    /* overflow-y: auto; */
}

.indextopicRoot img {
    width: 160px;
    height: unset;
    object-fit: cover;
    aspect-ratio: 2/1;
    border-radius: 8px 0 0 8px;
}

.mainHeadingRoot {
    padding-bottom: 40px;
    border-bottom: 1px solid #000;
    margin-bottom: 40px;
}

.titleCover_root {
    position: relative;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    border-radius: 0 0 50px 50px;
    width: 100%;
    height: unset;
    aspect-ratio: 2;
}

.titleCover_gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #F8F9FE 0%, rgba(248, 249, 254, 0) 100%);
    border-radius: 0 0 50px 50px;
}

.titleCover_root img {
    border-radius: 0 0 50px 50px;
    width: 100%;
    height: 100%;
}

.titleCover_info {
    position: absolute;
    top: 24px;
    z-index: 10;
    color: black;
    left: 50px;
}

.basicMargin {
    margin: 0 2cm;
}

.all_docs_card {
    box-shadow: -1px 3px 4px #cacaca;
    background-color: white;
    border-radius: 8px;
    text-align: center;
    position: relative;
}

.all_docs_card button{
    border: none;
    outline: none;
    padding: 8px;
    border-radius: 50%;
    position: absolute;
    top: 3px;
}

.all_docs_card .btn1{
    right: 50px
}
.all_docs_card .btn2{
    right: 10px;
    background-color: red;
    color: white;
}

.all_docs_card_img img {
    width: 100%;
}
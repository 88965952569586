/* In a CSS file */
@import url("https://fonts.googleapis.com/css2?family=Aref+Ruqaa&family=Mirza&family=Montserrat&family=Noto+Sans&family=Nunito&family=Open+Sans&family=Poppins&family=Ubuntu&display=swap");

.blog-editor .ql-editor {
  min-height: 70vh;
}
.blog-editor p {
  font-size: 1.6rem;
}
.blog-editor .ql-snow .ql-editor h1 {
  font-size: 85px;
  line-height: 128px;
}
.blog-editor .ql-snow .ql-editor h2 {
  font-size: 65px;
  line-height: 98px;
}
.blog-editor .ql-snow .ql-editor h3 {
  font-size: 49px;
  line-height: 74px;
}
.blog-editor .ql-snow .ql-editor h4 {
  font-size: 38px;
  line-height: 57px;
}
.blog-editor .ql-snow .ql-editor h5 {
  font-size: 29px;
  line-height: 44px;
}
.blog-editor .ql-snow .ql-editor h6 {
  font-size: 22px;
  line-height: 33px;
}
.blog_chooseCoverImage {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7d3d3;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: -1px 6px 6px 1px #ebe3e330;
}

/* css needs to add in zefyron */
/* .ql-editor .ql-align-center {
  text-align: center;
} */

.blog-editor .ql-toolbar {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}
.blog-editor-root {
  position: relative;
}
.blog_back-btn {
  position: fixed !important;
  top: 8rem;
  left: 10rem;
}

.blog_save_btn {
  position: fixed;
  top: 8rem;
  right: 10rem;
}


/* Set dropdown font-families */
/* .ql-snow .ql-picker.ql-size .ql-picker-label::before, .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;
} */

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before,
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;
  /* font-family: attr(data-value), sans-serif !important; */
}
.ql-picker-item[data-value="Noto Sans"] {
  font-family: "Noto Sans";
}
.ql-picker-item[data-value="Aref Ruqaa"] {
  font-family: "Aref Ruqaa";
}
.ql-picker-item[data-value="Mirza"] {
  font-family: "Mirza";
}
.ql-picker-item[data-value="Montserrat"] {
  font-family: "Montserrat";
}
.ql-picker-item[data-value="Nunito"] {
  font-family: "Nunito";
}
.ql-picker-item[data-value="Open Sans"] {
  font-family: "Open Sans";
}
.ql-picker-item[data-value="Poppins"] {
  font-family: "Poppins";
}
.ql-picker-item[data-value="Ubuntu"] {
  font-family: "Ubuntu";
}
.ql-font-Noto-Sans {
  font-family: "Noto Sans", sans-serif;
}
.ql-font-Aref-Ruqaa {
  font-family: "Aref Ruqaa", sans-serif;
}
.ql-font-Mirza {
  font-family: "Mirza", sans-serif;
}
.ql-font-Montserrat {
  font-family: "Montserrat", sans-serif;
}
.ql-font-Nunito {
  font-family: "Nunito", sans-serif;
}
.ql-font-Open-Sans {
  font-family: "Open Sans", sans-serif;
}
.ql-font-Poppins {
  font-family: "Poppins", sans-serif;
}
.ql-font-Ubuntu {
  font-family: "Ubuntu", sans-serif;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  display: block;
  margin-top: -1px;
  top: 100%;
  z-index: 1;
  max-height: 370px;
  overflow: hidden;
  overflow-y: auto;
}

.fn-tab{
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
}

.fn-tab-active{
  border-bottom: 2px solid #000;
  font-weight: 600;
}

.no-content{
  font-size: 15px;
  font-weight: 500;
}
.blog-options{
  position: relative;
  background-color: #eaeaea;
  top: 0;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blog-options button{
  all: unset;
  padding: 10px;
  color: black;
  cursor: pointer;  
}
.option-parent{
  padding: 6px;
}
.options{
  cursor: pointer;
  font-size: 14px;
  margin-top: 6px;
  padding: 8px 16px;
  border: 4px;
}
.options:hover{
  background-color:#eaeaea;
}
.blogs-images-drawer .MuiDrawer-paper {
  max-width: 90%;
  width: 500px;
}

.blogs-images-root {
  padding: 40px 24px;
}

.blogs-images-main{
    border: 2px solid #fff;
    transition: all 0.3s ease-in-out
}
.blogs-images-main:hover{
    border: 2px solid #000;
    transition: all 0.3s ease-in-out
}
.admin_articles_main table{
    width: 100%;
}
.admin_articles_main table,
.admin_articles_main th,
.admin_articles_main td {
    border: 1px solid black;
    border-collapse: collapse;
}

.admin_articles_thead_tr>th {
    font-size: 20px;
}

.admin_articles_tbody_desctd {
    width: 30%;
}

.admin_articles_tbody_companytd img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 20px;
}



.admin_articles_tbody_tr td {
    padding-top: 24px;
    padding-bottom: 24px;
}

.admin_articles_tbody_tr td:first-child,
.admin_articles_thead_tr th:first-child {
    padding-left: 24px;
}

.admin_articles_tbody_tr td:last-child,
.admin_articles_thead_tr th:last-child {
    padding-right: 24px;
}

.admin_articles_thead_tr th,
.admin_articles_tbody_tr td {
    text-align: center;
}

.table-cover {
    width: 150px;
    border-radius: 10px;
}
.home_root {
  min-height: 100vh;
  background-color: #000;
  color: white;
  height: 100vh;
}

.home_nav {
  height: 9vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home_left {
  width: 50%;
}

.home_right {
  width: 40%;
}
.home_text img {
  margin-bottom: 3em;
  height: 75px;
}
.home_nav_p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 2px solid #4ad7d1;
  color: white;
}

.home_main {
  height: 91vh;
}

.home-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 6.5rem;
  line-height: 7rem;
}

.home-desc {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.5);
}

.home_login-main {
  margin-top: 4rem;
  background: #1d1d1d;
  border: 1px solid #757575;
  border-radius: 100px;
  width: 60%;
  height: 72px;
  display: flex;
  align-items: center;
}

.home_login-main > input {
  background-color: transparent;
  height: 100%;
  border-radius: 100px 0 0 100px;
  border: none;
  outline: none;
  padding: 0 32px;
  width: 75%;
  color: white;
}

.home_login-main > button {
  background: #ffffff;
  border-radius: 94px;
  padding: 7.5px 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  width: 134px;
  margin: 0 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeBanner2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home_redirect_btn {
  background-color: #fff;
  border: none;
  margin: 25px 0;
  border-radius: 94px;
  font-size: 18px;
  font-family: Poppins;
  padding: 9px 40px 9px 32px;
  font-weight: 600;
}
.home_redirect_btn_svg {
  transition: 200ms ease-in-out;
}
.home_redirect_btn:hover .home_redirect_btn_svg {
  transform: translate(8px, 0);
}
.input_checkbox .MuiSvgIcon-root {
  border-radius: 2px;
  height: 24px;
  width: 24px;
  border: 2px solid #757575;
}
.input_checkbox .MuiButtonBase-root {
  padding-top: 4px;
}
.input_checkbox .Mui-checked {
  color: #fff !important;
}
.input_checkbox .Mui-checked .MuiSvgIcon-root {
  border: none;
}
@media (max-width: 992px) {
  .home_right {
    display: none;
  }
  .home_left {
    width: 100%;
  }
  .home-title {
    font-size: 6.5rem;
    line-height: 8rem;
  }
}
@media (max-width: 768px) {
  .home-title {
    font-size: 6rem;
    line-height: 7rem;
  }
  .home_login-main {
    width: 85%;
  }
}
@media (max-width: 576px) {
  .home-title {
    font-size: 5rem;
    line-height: 6rem;
  }
  .home_text,
  .home_nav {
    padding: 0 3rem;
  }
  .home_nav_p {
    display: none;
  }
  .home_login-main {
    width: 100%;
  }
  .home_login-main {
    height: 60px;
  }
}
@media (max-width: 480px) {
  .home-title {
    font-size: 4rem;
    line-height: 5rem;
  }
  .home_text,
  .home_nav {
    padding: 0 1rem;
  }
  .home_nav_p {
    display: none;
  }
}
@media (max-width: 435px) {
  .home-title {
    font-size: 3rem;
    line-height: 5rem;
    padding: 0 1rem;
  }
  .home-desc {
    font-size: 1.4rem;
    padding: 0 1rem;
    /* line-height: 5rem; */
  }
  .home_text,
  .home_nav {
    padding: 0 0.5rem;
  }
}
@media (max-width: 410px) {
  .home_login-main > input {
    width: 75%;
  }
  .home_text,
  .home_nav {
    padding: 0;
  }
}

.admin-navbar-root {
    height: 61px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(117 117 117 / 10%);
    display: flex;
    align-items: center;
}

.admin-navbar-right {
    display: flex;
    align-items: center;
}

.admin-navbar-hamburger {
    display: none;
}

@media (max-width:576px) {
    .admin-navbar-right {
        display: none;
    }

    .admin-navbar-hamburger {
        display: block;
    }

    .admin-navbar-main{
        padding: 0 2rem;
    }

}
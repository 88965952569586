.article-page_head {
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.article-main {
    padding: 24px;
    background: #F7F9FE;
    border-radius: 0px 0px 24px 24px;
}

.article-title>p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 3.4rem;
    line-height: 5.1rem;
    text-align: center;
    color: #000000;
}

.dashboard-articlepage-root {
    display: flex;
    justify-content: center;
    align-items: center;
}

.article-img>img {
    width: 100%;
    border-radius: 24px 24px 0px 0px;
}

.article-img {
    border-radius: 24px 24px 0px 0px;
}

.dashboard-article-main2 {
    width: 75%;
}

.article-main-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 3.3rem;
}

.article-desc {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.2rem;
}

.article-logo>img {
    height: 60px;
}

.article-link>a {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    color: #404654;
}

@media (max-width:800px) {
    .article-title>p {
        font-size: 3rem;
    }
}

@media (max-width:768px) {
    .article-title>p {
        font-size: 2.5rem;
    }

    .article-main-title {
        font-size: 2rem;
        line-height: 3rem;
    }
}

@media (max-width:576px) {
    .article-title>p {
        font-size: 2rem;
        line-height: 3rem;
    }

    .article-title {
        padding: 0 2rem;
    }

    .article-main-title {
        font-size: 1.6rem;
        line-height: 2rem;
    }

    .article-desc {
        font-size: 1.2rem;
        line-height: 1.6rem;
    }
}

@media (max-width:400px) {
    .article-title>p {
        font-size: 1.8rem;
        line-height: 2.5rem;
    }

    .article-title {
        padding: 0 2rem;
    }

    .article-main-title {
        font-size: 1.4rem;
        line-height: 2rem;
    }
}